@import "./fonts.css";
@import "./swiper-pagination.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-hind: "Hind";
  --first-color: #f4f4f4;
  --second-color: #1c1c1c;
}

html {
  font-family: var(--font-hind);
  font-size: 16px;
  font-weight: 400;
  min-width: 320px;
}

body {
  background-color: var(--second-color);
  color: var(--first-color);
}
