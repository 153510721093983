@font-face {
    font-family: Hind;
    font-display: fallback;
    src: url("../assets/fonts/hind/Hind-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Hind;
    font-display: fallback;
    src: url("../assets/fonts/hind/Hind-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Hind;
    font-display: fallback;
    src: url("../assets/fonts/hind/Hind-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Hind;
    font-display: fallback;
    src: url("../assets/fonts/hind/Hind-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}